import React from 'react';
import Styles from './styles.module.scss';
import BackgroundImageLayout from "../../../layouts/BackgroundImageLayout";
import backgroundImg from '../../../globals/assets/background_home.png'
import scanImg from '../../../globals/assets/scan.svg'
import Button from "../../../components/Button";
import ERoutes from "../../../routers/AuthRouter/routes";
import {LocaleConsumer} from '../../../providers/LocaleProvider';
import HTMLRender from "../../../components/HTMLRender";

const Home : React.FC = () => (
    <LocaleConsumer>
        {({ getTranslation }) => (
            <BackgroundImageLayout image={backgroundImg}>
                <div className={Styles["home__container"]}>
                    <div className={Styles["home"]}>
                        <img alt="placehodler" className={Styles["home__image"]} src={scanImg}/>
                        <h2 className={Styles["home__text"]}>
                            <HTMLRender>{getTranslation("scan_home_page.description")}</HTMLRender>
                        </h2>
                        <Button styleType="plain" size="small" type="button" link={ERoutes.Scanner} label={getTranslation("scan_home_page.start_scanning")}/>
                    </div>
                </div>
            </BackgroundImageLayout>
        )}
    </LocaleConsumer>
);

export default Home;
