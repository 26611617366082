enum ERoutes {
    /**
     * Login page, use by API to redirect in case of token expiration.
     */
    Login = '/',

    /**
     * Select rep's client
     */
    SelectClient = "/clients"
}

export default ERoutes;