import React, {useContext, useEffect, useState} from "react";
import Styles from "./styles.module.scss";
import {Form} from "antd";
import InputText from "../../components/InputText";
import {IDeliveryHomeForm} from "./interfaces";
import {AuthContext} from "../../providers/AuthProvider";
import {LocaleConsumer, LocaleContext} from "../../providers/LocaleProvider";
import Fuse from "fuse.js";
import countriesLib from 'i18n-iso-countries'
import InputAutoComplete from "../../components/InputAutoComplete";
import {Rule} from "rc-field-form/lib/interface";
import {getCountries} from "../../helpers/API/requests/country";
import {ELocale} from "../../providers/LocaleProvider/interfaces";
import {TCountry} from "../../helpers/API/requests/interfaces/country";

countriesLib.registerLocale(require("i18n-iso-countries/langs/en.json"));
countriesLib.registerLocale(require("i18n-iso-countries/langs/fr.json"));

const g_subsidiaryUSA = "040";
const g_conditionSquareMarketNotAllowed = [g_subsidiaryUSA];

const DeliveryHomeForm: React.FC<IDeliveryHomeForm> = ({ formHook , onSubmit, onFieldsChange }) => {
    const authContext = useContext(AuthContext);
    const localeContext = useContext(LocaleContext);
    const [shippingName, setShippingName] = useState("");
    const [shippingContactName, setShippingContactName] = useState<string>("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [postal, setPostal] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [countriesResult, setCountriesResult] = useState<string[]>([]);
    const [phone, setPhone] = useState("");
    const [countries, setCountries] = useState<TCountry[]>([]);

    useEffect(() => {
        getCountries().then(countries => {
            setCountries(countries.data);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rules = React.useMemo<Rule[]>(() => ([{
        required: true,
        message: localeContext.getTranslation("order_page.form.mandatory_field"),
        validateTrigger: 'onSubmit'
    }]), [localeContext]);

    const cityValidatorIsOnError = React.useMemo(() => postal.length + city.length >= 36, [postal, city]);

    const handleSearch = React.useCallback((value: string)  => {
        const fuse = new Fuse(countries, {
            keys: [localeContext.locale === ELocale.frFr ? "countryNameFR" : "countryNameEN"],
            ignoreLocation: true,
            threshold: 0.2,
        })
        setCountriesResult(
            Array.from(new Set<string>(fuse.search(value).map(
                (elem) => localeContext.locale === ELocale.frFr ? elem.item.countryNameFR : elem.item.countryNameEN
            )
        )))
    }, [countries, localeContext.locale]);

    const onFinish = () => {
        if (cityValidatorIsOnError) {
            return ;
        }

        const countryCode = countries.find(elem => {
            return (localeContext.locale === ELocale.frFr ?
                elem.countryNameFR === country
                :
                elem.countryNameEN === country
            )
        })?.countryCode;

        const tmpUserInfo = { ...authContext.user! };
        const billingName = tmpUserInfo!.client!.accountName;
        tmpUserInfo!.client!.accountName = shippingName;

        onSubmit({
                clientCode: tmpUserInfo?.client?.clientServiceCode,
                address1: address1,
                address2: address2,
                address3: city,
                address4: country,
                city,
                postalCode: postal,
                stateOrDepartment: state,
                countryCode: countryCode,
                countryName: country
            },
            tmpUserInfo,
            shippingContactName,
            billingName,
            phone
        );
    };

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <Form
                    form={formHook}
                    onFinish={onFinish}
                    className={Styles["deliveryHome__form"]}
                    action=""
                    onFieldsChange={onFieldsChange}
                >
                    <div className={Styles["deliveryHome__inputs"]}>
                        <InputText
                            label={getTranslation("order_page.form.name")}
                            fieldName="billingName"
                            value={shippingName}
                            setValue={setShippingName}
                            rules={rules.concat({
                                    max: 36,
                                    message: getTranslation("order_page.form.max_length", {params: {max: 36}})
                            })}
                        />
                        <InputText
                            label={getTranslation('order_page.shipping_contact_name')}
                            fieldName={"shippingContactName"}
                            value={shippingContactName}
                            setValue={setShippingContactName}
                            rules={rules.concat({
                                max: 36,
                                message: getTranslation("order_page.form.max_length", {params: {max: 36}})
                            })}
                        />
                        <InputText
                            label={getTranslation("order_page.form.address1")}
                            fieldName="address1"
                            value={address1}
                            setValue={setAddress1}
                            rules={rules.concat({
                                max: 36,
                                message: getTranslation("order_page.form.max_length", {params: {max: 36}})
                            })}
                        />
                        <InputText
                            label={getTranslation("order_page.form.address2")}
                            fieldName="address2"
                            value={address2}
                            setValue={setAddress2}
                            rules={[{
                                max: 36,
                                message: getTranslation("order_page.form.max_length", {params: {max: 36}})
                            }]}
                        />
                        <InputText
                            label={getTranslation("order_page.form.postal_code")}
                            fieldName="postalCode"
                            value={postal}
                            setValue={setPostal}
                            rules={rules.concat({
                                max: 10,
                                message: getTranslation("order_page.form.max_length", {params: {max: 10}})
                            })}
                        />
                        <InputText
                            label={getTranslation("order_page.form.city")}
                            fieldName="city"
                            value={city}
                            setValue={setCity}
                            error={cityValidatorIsOnError ? getTranslation("order_page.form.max_length", {params: {max: postal.length ? 35 - postal.length : 36 }}) : null}
                            rules={rules}
                        />
                        <InputText
                            label={getTranslation("order_page.form.state.label")}
                            help={getTranslation("order_page.form.state.help")}
                            className='mb-6'
                            fieldName="state"
                            value={state}
                            setValue={setState}
                            rules={[{
                                required: g_conditionSquareMarketNotAllowed.includes(authContext.user?.client?.pierreFreySubsidiary!),
                                max: 2,
                                message: getTranslation("order_page.form.invalid_state")
                            }]}
                            maxLength={2}
                        />
                        <InputAutoComplete
                            label={getTranslation("order_page.form.country")}
                            fieldName="country"
                            value={country}
                            setValue={setCountry}
                            rules={[
                                {
                                    required: true,
                                    message: localeContext.getTranslation("order_page.form.mandatory_field"),
                                },
                                () => ({
                                    validator(_, value) {
                                        if (countries.find(elem => localeContext.locale === ELocale.frFr ? elem.countryNameFR === value : elem.countryNameEN === value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(getTranslation("order_page.form.country_not_found"));
                                    }
                                })
                            ]}
                            onSearch={handleSearch}
                            results={countriesResult}
                        />
                        <InputText
                            label={getTranslation("order_page.form.phone.label")}
                            help={getTranslation("order_page.form.phone.help")}
                            className='mb-6'
                            fieldName="phone"
                            value={phone}
                            setValue={setPhone}
                            rules={rules.concat({
                                max: 16,
                                message: getTranslation("order_page.form.max_length", {params: {max: 16}})
                            })}
                        />
                    </div>
                </Form>
            )}
        </LocaleConsumer>
    )
}

export default DeliveryHomeForm