import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { notification, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import useQuery from "../../../hooks/useQuery";

import ERoutes from "../../../routers/AuthRouter/routes";

import { AuthContext } from "../../../providers/AuthProvider";
import { CartContext } from "../../../providers/CartProvider";
import { LocaleConsumer, LocaleContext } from '../../../providers/LocaleProvider';
import { ProductsContext } from "../../../providers/ProductProvider";

import BottomMenuLayout from "../../../layouts/BottomMenuLayout";

import Button from "../../../components/Button";
import ProductStock from "../../../components/ProductStock";
import Spinner from "../../../components/Spinner";
import SwitchSelect from "../../../components/SwitchSelect";

import { getPrice } from "../../../helpers/API/requests/price";
import { getStock } from "../../../helpers/API/requests/stock";
import { EProductStatus, TProduct } from "../../../helpers/API/requests/interfaces/products";
import { TStock } from "../../../helpers/API/requests/interfaces/stock";

import Styles from './styles.module.scss';

const g_subsidiaryUSA = "040";
const g_subsidiaryUK = "020";

const g_jimThompsonBrandCode = "30";

const g_conditionRenderPrice = [g_subsidiaryUSA, g_subsidiaryUK];

const g_conditionSquareMarketNotAllowed = [g_subsidiaryUSA];

const g_conditionJimThompsonBrandNotAuthorize = [g_subsidiaryUSA, g_subsidiaryUK];

const Product: React.FC = () => {
    const query = useQuery()
    const code = query.get("code");

    const history = useHistory();

    const cartContext = useContext(CartContext);
    const productsContext = useContext(ProductsContext);
    const authContext = useContext(AuthContext);
    const localeContext = useContext(LocaleContext);

    const [product, setProduct] = useState<TProduct | undefined>(undefined);
    const [stock, setStock] = useState<TStock | undefined>(undefined);
    const [stockLoading, setStockLoading] = useState<boolean>(true);
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [currency, setCurrency] = useState<string | undefined>(undefined);

    const g_thorpBrandCode = "17";

    const isDiscontinued = React.useMemo(() => product?.status === EProductStatus.DISCONTINUED, [product?.status])
    const isJimThompsonBrand = React.useMemo(() => product?.brandCode === g_jimThompsonBrandCode, [product?.brandCode])
    const isThorpBrand = React.useMemo(() => product?.brandCode === g_thorpBrandCode, [product?.brandCode]);


    useEffect(() => {
        if (productsContext.loading) {
            return ;
        }

        if (!code) {
            history.push(ERoutes.Scanner);
            return;
        }

        const tmpProduct = productsContext.products.find(product => product.code.toUpperCase() === code.toUpperCase())
        if (!tmpProduct) {
            notification['error']({
                placement: "bottomLeft",
                message: localeContext.getTranslation("product_page.product_not_found_error"),
                description: `code: ${code}`,
            });
            return;
        }
        tmpProduct.size = "small";
        setProduct(tmpProduct);

        getStock(
            tmpProduct.code,
            authContext.user!.client!.accountCode!,
            authContext.user!.client!.pierreFreySubsidiary!
        ).then((data) => {
            setStock(data.data);
            setStockLoading(false);
        });

        getPrice(
            tmpProduct.code,
            authContext.user?.client?.accountCode!,
            authContext.user?.client?.currencyCode!,
            authContext.user?.client?.priceListProduct!,
            authContext.user?.client?.priceListSample!
        )
        .then(data => {
            if (data.data.length > 0) {
                setPrice(data.data[0].price);
                setCurrency(data.data[0].currencyCode);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productsContext.loading]);

    const addItem = () => {
        cartContext.add(product!)
        history.push(ERoutes.Cart)
    };

    const onToggleType = (checked: boolean) => {
        setProduct({...product, size: checked ? "large" : "small"} as TProduct)
    };

    return (
        <LocaleConsumer>
            {({ getTranslation }) => (
                <>
                    {!product ?
                        <div className={Styles["product"]}>
                            <div />
                            <h2>{productsContext.loading ? getTranslation('product_page.page_loading', { params: { code: code! } }) : getTranslation("product_page.product_not_found", { params: { code: code! } })}</h2>
                            <BottomMenuLayout>
                                <Button
                                    className={Styles["bottomMenu__button"]}
                                    styleType="text"
                                    size="large"
                                    type="button"
                                    link={ERoutes.Scanner}
                                    label={getTranslation("product_page.scan_product")}
                                />
                            </BottomMenuLayout>
                        </div>
                        :
                        <div className={Styles["product"]}>
                            <div className={Styles["product__description"]}>
                                <img alt="product" src={product.pictureUrl} className={Styles["product__image"]}/>
                                <h1 className={Styles["product__name"]}>{product.pattern} {product.color}</h1>
                                <h2 className={Styles["product__code"]}>{product.code}</h2>
                                {price && stock?.unit !== "UN" && g_conditionRenderPrice.includes(authContext.user?.client?.pierreFreySubsidiary!) &&
                                    <h3 className={Styles["product__price"]}>{`${currency} ${price} ${getTranslation("product_page.unit." + stock?.unit)}`}</h3>
                                }
                                {isDiscontinued ? (
                                    <div className={Styles["product__discontinued"]}>
                                        <Tooltip title={getTranslation("product_page.discontinued_tooltip")} placement="topRight" className={Styles["switchSelect__tooltip"]}>
                                            <p className={Styles["product__discontinued--text"]}>{getTranslation("product_page.discontinued")}</p>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </div>
                                ) : undefined}
                                {stockLoading ?
                                    <Spinner />
                                :
                                    <ProductStock stock={stock}/>
                                }
                            </div>
                            <BottomMenuLayout>
                                {product.family === "Fabrics" && !g_conditionSquareMarketNotAllowed.includes(authContext.user?.client?.pierreFreySubsidiary!) && !isDiscontinued && !isThorpBrand &&
                                    <SwitchSelect
                                        leftLabel={getTranslation("product_page.tirelle")}
                                        leftTooltip={getTranslation("product_page.tirelle_tooltip")}
                                        rightLabel={getTranslation("product_page.carre_a_condition")}
                                        rightTooltip={getTranslation("product_page.carre_a_condition_tooltip")}
                                        onSwitch={onToggleType}
                                        defaultValue={false}
                                    />
                                }
                                {((!cartContext.items.find(elem => elem.product.code === product.code && elem.product.size === product.size) || cartContext.items.find(elem => elem.product.code === product.code && elem.product.size === product.size)?.number! < 3)) ?
                                    !(isDiscontinued || (isJimThompsonBrand && g_conditionJimThompsonBrandNotAuthorize.includes(authContext.user?.client?.pierreFreySubsidiary!))) ? (
                                        <Button
                                            className={Styles["bottomMenu__button"]}
                                            size="large"
                                            styleType="plain"
                                            type="button"
                                            onClick={addItem}
                                            label={getTranslation("product_page.add_to_cart")}
                                        />
                                    ) : undefined
                                :
                                    <Button
                                        className={Styles["bottomMenu__button"]}
                                        styleType="plain"
                                        size="large"
                                        type="button"
                                        link={ERoutes.Cart}
                                        label={getTranslation("product_page.go_to_cart")}
                                    />
                                }
                                <Button
                                    className={Styles["bottomMenu__button"]}
                                    styleType="text"
                                    size="large"
                                    type="button"
                                    link={ERoutes.Scanner}
                                    label={getTranslation("product_page.scan_product")}
                                />
                            </BottomMenuLayout>
                        </div>
                    }
                </>
            )}
        </LocaleConsumer>
    )
}

export default Product;