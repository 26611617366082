import React, {useEffect} from "react";
import reducer from "./reducer"
import {IShowroomProps, IShowroomsState} from "./interfaces";
import {getActivePWAShowrooms} from "../../helpers/API/requests/showrooms";

export const ShowroomsContext = React.createContext<IShowroomProps>({
    showrooms: [],
    set: () => {},
});

ShowroomsContext.displayName = 'Showrooms';

export const ShowroomsConsumer = ShowroomsContext.Consumer;

const initialState: {state: IShowroomsState, ttl: number} = JSON.parse(localStorage.getItem("showrooms") as string)

const ShowroomsProvider: React.FC = ({
    children
}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState?.state || {showrooms: []});

    useEffect(() => {
        const now = new Date()
        if (!initialState || initialState.state.showrooms.length === 0 || initialState.ttl <= now.getTime()) {
            getActivePWAShowrooms()
            .then((data) => {
                showroomsContext.set(data.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const now = new Date();
        localStorage.setItem("showrooms", JSON.stringify({state: state, ttl: now.getTime() + (1000 * 60 * 60 * 24)}))
    }, [state])

    const showroomsContext = React.useMemo<IShowroomProps>(() => ({
        ...state,

        set: showrooms => {
            dispatch({showrooms: showrooms})
        }
    }), [state])

    return (
        <ShowroomsContext.Provider value={showroomsContext}>
            {children}
        </ShowroomsContext.Provider>
    )
}

export default ShowroomsProvider