import React from 'react';

import { IButtonProps } from './interfaces';
import Styles from './styles.module.scss';
import {useHistory} from 'react-router-dom';

const Button : React.FC<IButtonProps> = ({
    styleType = "plain",
    size = "small",
    type = "button",
    onClick,
    label,
    link,
    className,
    disabled
}) => {
    let history = useHistory()
    const onButtonClick = async () => {
        await new Promise(r => setTimeout(r, 150));
        if (onClick) {
            onClick()
        }
    }
    const onLinkClick = async () => {
        await new Promise(r => setTimeout(r, 150));
        history.push(link as string);
    }
    return (
        <div className={className}>
            {link === undefined ?
                <button
                    type={type}
                    className={`${Styles.button} ${Styles[`button__${styleType}`]} ${Styles[`button__${size}`]}`}
                    onClick={onButtonClick}
                    disabled={disabled}
                >
                    <p>{label}</p>
                </button>
                :
                <button
                    onClick={onLinkClick}
                    className={`${Styles.button} ${Styles[`button__${styleType}`]} ${Styles[`button__${size}`]}`}
                    disabled={disabled}
                >
                    <p>{label}</p>
                </button>
            }
        </div>
    )
}

export default Button;
