import {TCartItem} from "../../../../providers/CartProvider/interfaces";
import {TAddress} from "./address";
import {TUser} from "../../../../providers/AuthProvider/interfaces";

export enum EShippingAddressType {
    /**
     * From showroom
     */
    SHOWROOM = '01',

    /**
     * From selected client's list
     */
    CLIENT_LIST = '02',

    /**
     * Manual input
     */
    ADDRESS = '03'
}

export type THeader = {
    subsidiary_code: string;
    account_code: string;
    shipping_contact_email: string;
    rep_code?: string;
    currency?: string;
    billing_name?: string;
    billing_contact_name?: string;
    billing_street_1?: string;
    billing_street_2?: string;
    billing_postal_code?: string;
    billing_city?: string;
    billing_state_or_department?: string;
    billing_country_code: string;
    billing_country_name?: string;
    billing_contact_email?: string;
    shipping_name: string;
    shipping_contact_name: string;
    shipping_street_1?: string;
    shipping_street_2?: string;
    shipping_postal_code?: string;
    shipping_city?: string;
    shipping_state_or_department?: string;
    shipping_country_code: string;
    shipping_country_name?: string;
    shipping_address_type: EShippingAddressType;
    phone?: string;
    reference?: string;
    specifier?: string;
    project_name?: string;
    showroom_code?: string;
    // each comment max length is 60, total of 360 char
    comment_1?: string;
    comment_2?: string;
    comment_3?: string;
    comment_4?: string;
    comment_5?: string;
    comment_6?: string;
}

export type TOrderLine = {
    product_code: string;
    small_size: string;
    large_size: string;
    quantity: number;
    unit?: string;
    unit_price?: number;
    extra_discount_percentage?: number;
    privilege_discount_percentage?: number;
    expiry_date?: string;
    price_list?: string;
    VAT_percentage?: number;
}

export type TOrderRequest = {
    order_type: string;
    header: THeader;
    order_lines: TOrderLine[];
}

export type TOrderResponseLine = {
    finalProductCode?: string;
    erpIdOrderLine?: string;
    erpIdOrderHeader?: string;
    lineID?: string;
    productCode?: string;
    smallSize?: string;
    bigSize?: string;
    quantity?: number;
    unit?: string;
    unitPrice?: number;
    extraDiscountPercentage?: number;
    privilegeDiscountPercentage?: number;
    expiryDate?: string;
    priceList?: string;
    vatPercentage?: number;
}

export type TOrder = {
    orderNumber?: string;
    allExceptUSFreeProductsOrderNumber?: number;
    onlyUsFreeProductsOrderNumber?: number;
    orderLine?: TOrderResponseLine[];
}

export type TOrderResponse = {
    data: TOrder[]
}

export type TRequestOrderCallback = (
    cartItems: TCartItem[],
    address: TAddress,
    user: TUser,
    shippingContactName: string,
    shippingAddressType: EShippingAddressType,
    billingName?: string,
    phone?: string,
    comment?: string,
) => Promise<TOrderResponse>;