import React from 'react';

import {Route, Router, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";

import AuthProvider, { AuthConsumer, TAuthRetrieveUserCallback } from '../../providers/AuthProvider';
import CartProvider from "../../providers/CartProvider";
import {getUserFromCookie} from "../../helpers/API/requests/login";
import LocaleProvider from "../../providers/LocaleProvider";
import {getTranslation} from "../../helpers/API/requests/translation";

/**
 * Routers
 */
import NotAuthRouter from '../NotAuthRouter';
import AuthRouter from '../AuthRouter';

import InvalidateProvider from '../../providers/InvalidateProvider';
import ProductsProvider from "../../providers/ProductProvider";
import ShowroomsProvider from "../../providers/ShowroomProvider";
import OnboardingProvider from '../../providers/OnboardingProvider';

import InfoPage from "../../screens/InfoPage";
import PrivacyPolicyPage from "../../screens/PrivacyPolicyPage";

import EGlobalRoutes from "./routes";

/**
 * Get router's history
 */
export const history = createBrowserHistory();

const onRetrieveUserFromCookie: TAuthRetrieveUserCallback = _ => getUserFromCookie()

const AppRouter: React.FC = () => (
    <Router history={history}>
        <InvalidateProvider>
            <OnboardingProvider>
                <LocaleProvider getTranslationFromRemote={getTranslation} renderKeyIfNotFound={false}>
                    <AuthProvider onRetrieveUserFromCookie={onRetrieveUserFromCookie} >
                        <ShowroomsProvider>
                            <Switch>
                                <Route exact path={EGlobalRoutes.PrivacyPolicy}>
                                    <PrivacyPolicyPage />
                                </Route>
                                <Route exact path={EGlobalRoutes.Infos}>
                                    <InfoPage />
                                </Route>
                                <Route>
                                    <AuthConsumer>
                                        {({ user }) => (user === null) ?
                                            <NotAuthRouter />
                                        :
                                            <ProductsProvider>
                                                <CartProvider>
                                                    <AuthRouter />
                                                </CartProvider>
                                            </ProductsProvider>
                                        }
                                    </AuthConsumer>
                                </Route>

                            </Switch>
                        </ShowroomsProvider>
                    </AuthProvider>
                </LocaleProvider>
            </OnboardingProvider>
        </InvalidateProvider>
    </Router>
);

export default AppRouter;