import { useRef, forwardRef, useImperativeHandle } from "react";
import * as SDCCore from "scandit-web-datacapture-core";
import * as SDCBarcode from "scandit-web-datacapture-barcode";
import { IScannerProps } from "./interfaces";

const licenseKey = process.env.REACT_APP_SCANDIT_SDK!;

const Scanner = forwardRef<any, IScannerProps>(({ onResult }, ref) => {
  const barcodePickerRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    stop: () => {
      barcodePickerRef.current?.destroy();
    },
  }));
  async function runScanner() {
    await SDCCore.configure({
      licenseKey: licenseKey,
      libraryLocation:
        "https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/",
      moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
    });

    const context = await SDCCore.DataCaptureContext.create();

    const camera = SDCCore.Camera.default;
    await context.setFrameSource(camera);

    const settings = new SDCBarcode.BarcodeCaptureSettings();
    settings.enableSymbologies([
      SDCBarcode.Symbology.Code128,
      SDCBarcode.Symbology.Code39,
      SDCBarcode.Symbology.QR,
    ]);

    const symbologySetting = settings.settingsForSymbology(
      SDCBarcode.Symbology.Code39
    );
    symbologySetting.activeSymbolCounts = [
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ];

    const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(
      context,
      settings
    );
    await barcodeCapture.setEnabled(false);

    barcodeCapture.addListener({
      didScan: async (barcodeCapture, session) => {
        await barcodeCapture.setEnabled(false);
        const barcode = session.newlyRecognizedBarcodes[0];
        const symbology = new SDCBarcode.SymbologyDescription(
          barcode.symbology
        );

        showResult(barcode.data, symbology.readableName);
      },
    });

    const view = await SDCCore.DataCaptureView.forContext(context);

    const targetElement = document.getElementById("data-capture-view");

    if (targetElement !== null) {
      view.connectToElement(targetElement);
    } else {
      console.error("Element with id 'data-capture-view' not found");
    }

    // view.connectToElement(document.getElementById("data-capture-view"));
    view.addControl(new SDCCore.CameraSwitchControl());

    const barcodeCaptureOverlay = await SDCBarcode.BarcodeCaptureOverlay.withBarcodeCaptureForViewWithStyle(
      barcodeCapture,
      view,
      SDCBarcode.BarcodeCaptureOverlayStyle.Frame
    );

    const viewfinder = new SDCCore.RectangularViewfinder(
      SDCCore.RectangularViewfinderStyle.Rounded,
      SDCCore.RectangularViewfinderLineStyle.Bold
    );

    await barcodeCaptureOverlay.setViewfinder(viewfinder);

    await camera.switchToDesiredState(SDCCore.FrameSourceState.On);
    await barcodeCapture.setEnabled(true);
  }

  function showResult(data: string | null, symbology: string) {
    onResult(data!);
  }

  runScanner().catch((error) => {
    console.error(error);
    alert(error);
  });

  return <div id="data-capture-view"></div>;
});

export default Scanner;
