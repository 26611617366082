import React, {useState} from "react"
import Styles from './styles.module.scss'
import logo from "../../globals/assets/logo_PF.png"
import { MenuOutlined } from "@ant-design/icons"
import Menu from "../../components/Menu"
import {AuthConsumer} from "../../providers/AuthProvider"
import {history} from "../../routers/AppRouter";
import ERoutes from "../../routers/AuthRouter/routes";
import * as NotAuthRoutes from "../../routers/NotAuthRouter/routes";

const MenuLayout: React.FC = ({
    children
}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const open = React.useCallback(() => setMenuOpen(true), []);

    return (
        <AuthConsumer>
            {({ user }) => (
                <>
                    <header className={`${Styles["header"]}${menuOpen ? ` ${Styles['header__open']}` : ''}`}>
                        {user ?
                            <div onClick={open} className={Styles["header__hamburger"]}>
                                <MenuOutlined className={Styles["header__hamburgerIcon"]}/>
                            </div>
                            :
                            <div />
                        }
                        <img alt="logo" src={logo} className={Styles["header__logo"]} onClick={() => {
                            if (user)
                                history.push(ERoutes.Scanner)
                            else
                                history.push(NotAuthRoutes.default.Login)
                        }}/>
                        {/*<div className={Styles["header__infoIcon"]} onClick={() => history.push(EGlobalRoutes.Infos)}>*/}
                        {/*    <Info />*/}
                        {/*</div>*/}
                        <div className={Styles["header_edge"]} />
                    </header>
                    <main className={Styles["main"]}>
                        {menuOpen && <Menu isOpen={menuOpen} setIsOpen={setMenuOpen}/>}
                        {children}
                    </main>
                </>
            )}
        </AuthConsumer>
    )
}

export default MenuLayout